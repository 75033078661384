import * as actions from './actions'
import * as api from './../api/api'
import apiAxios from "../api/apiAxios";
import Swal from 'sweetalert2'

const options = {
    headers: { 'content-type': 'application/json' },
}

export const displayAlert = (error) => () => {
    alert(error)
}

export const loadLivros = () => async (dispatch, getState) => {
    try {
        dispatch(actions.loadLivrosInProgress())

        api.fetchLivros().then((resp) => {
            if (resp === null || resp === '') {
                dispatch(actions.loadLivrosFailure())
            } else {
                dispatch(actions.loadLivrosSuccess(resp))
            }
        })
    } catch (e) {
        dispatch(actions.loadLivrosFailure())
    }
}

export const addNewLivroRequest = (nome) => async (dispatch) => {
    try {
        const novoLivro = JSON.stringify({ nome })
        let url = '/api/livro'
        apiAxios
            .post(url, novoLivro, options)
            .then((resp) => dispatch(actions.addLivroRegistro(novoLivro)))
            .catch((error) => {
                // Error
                if (error.response) {
                    if (error.response.status !== 200) {
                        dispatch(
                            actions.addLivroRegistroFailure(
                                error.response.status
                            )
                        )
                    }
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the
                    // browser and an instance of
                    // http.ClientRequest in node.js
                    dispatch(actions.failure())
                    console.log(error.request)
                } else {
                    // Something happened in setting up the request that triggered an Error
                    dispatch(actions.failure())
                    console.log('Error', error.message)
                }
            })
    } catch (e) {
        dispatch(actions.failure())
    }
}

export const removeLivroRequest = (id) => async (dispatch) => {
    try {
        let url = `/api/livro/${id}`
        apiAxios
            .delete(url)
            .then((resp) => dispatch(actions.removeLivroRegistro(id)))
            .catch((error) => {
                // Error
                if (error.response) {
                    if (error.response.status !== 200) {
                        dispatch(
                            actions.removeLivroRegistroFailure(
                                error.response.status
                            )
                        )
                    }
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the
                    // browser and an instance of
                    // http.ClientRequest in node.js
                    dispatch(actions.removeLivroRegistroFailure())
                    console.log(error.request)
                } else {
                    // Something happened in setting up the request that triggered an Error
                    dispatch(actions.removeLivroRegistroFailure())
                    console.log('Error', error.message)
                }
            })
    } catch (e) {
        dispatch(actions.removeLivroRegistroFailure())
    }
}

/* export const clearEstudantes = () => async (dispatch, getState) => {
    try {
        dispatch(actions.clearEstudantesInProgress())
    } catch (e) {
        dispatch(actions.loadEstudantesFailure())
    }
} */

export const clearEstudantesFormandos = () => async (dispatch, getState) => {
    try {
        dispatch(actions.clearEstudantesFormandosInProgress())
    } catch (e) {
        dispatch(actions.loadEstudantesFormandosFailure())
    }
}

export const loadEstudantesFormandos = (dto) => async (dispatch, getState) => {
    try {
        dispatch(actions.loadEstudantesFormandosInProgress())
        dispatch(actions.clearEstudantesFormandosInProgress())
        if (dto) {
            api.getEstudantesFormandosIndividual(dto).then((resp) => {
                if (resp === null || resp === '') {
                    dispatch(actions.loadEstudantesFormandosFailure())
                } else {
                    validaHistoricoEstudantes(resp).then((estudantes) => {
                        dispatch(actions.loadEstudantesFormandosSuccess(estudantes))
                    })
                }
            })
        } else {
            dispatch(actions.loadEstudantesFormandosFailure())
        }
    } catch (e) {
        dispatch(actions.loadEstudantesFormandosFailure())
    }
}

export const loadEstudantes = (dataColacaoId) => async (dispatch, getState) => {
    try {
        dispatch(actions.loadEstudantesInProgress())
        dispatch(actions.clearEstudantesInProgress())
        if (dataColacaoId) {
            api.getEstudantesFormandos(dataColacaoId).then((resp) => {
                if (resp === null || resp === '') {
                    dispatch(actions.loadEstudantesFailure())
                } else {
                    validaHistoricoEstudantes(resp).then((estudantes) => {
                        dispatch(actions.loadEstudantesSuccess(estudantes))
                    })
                }
            })
        } else {
            dispatch(actions.loadEstudantesFailure())
        }
    } catch (e) {
        dispatch(actions.loadEstudantesFailure())
    }
}

async function validaHistoricoEstudantes(estudantes) {
    const estudantesComHistorico = await Promise.all(
        estudantes.map(async (aluno) => {
            if (!aluno.registroDiplomaAtivo) {
                let disciplinas = [];
                if (aluno.sigaRGAAluno) {
                    disciplinas = await api.getDisciplinasComPendencia(aluno.sigaRGAAluno);
                }
                else if (aluno.matricula) {
                    disciplinas = await api.getDisciplinasComPendencia(aluno.matricula);
                }
                aluno.historico = disciplinas;
            }
            return aluno;
        })
    )
    return estudantesComHistorico;
}

export const loadRegistros = () => async (dispatch, getState) => {
    try {
        dispatch(actions.loadRegistrosInProgress())

        api.fetchRegistros().then((resp) => {
            if (resp === null || resp === '') {
                dispatch(actions.loadRegistrosFailure())
            } else {
                dispatch(actions.loadRegistrosSuccess(resp))
            }
        })
    } catch (e) {
        dispatch(actions.loadRegistrosFailure())
    }
}

export const loadRegistrosPeriodo = (dataInicio, dataFim) => async (dispatch, getState) => {
    try {
        dispatch(actions.loadRegistrosInProgress())

        api.fetchRegistrosPeriodo(dataInicio, dataFim).then((resp) => {
            if (resp === null || resp === '') {
                dispatch(actions.loadRegistrosFailure())
            } else {
                dispatch(actions.loadRegistrosSuccess(resp))
            }
        })
    } catch (e) {
        dispatch(actions.loadRegistrosFailure())
    }
}

export const loadRegistrosSelecionados = (registrosSelecionados) => async (dispatch, getState) => {
    try {
        dispatch(actions.loadRegistrosSelecionadosInProgress())

        api.fetchRegistrosSelecionados(registrosSelecionados).then((resp) => {
           // console.log(resp)
            if (resp === null || resp === '') {
                dispatch(actions.loadRegistrosSelecionadosFailure())
            } else {
                dispatch(actions.loadRegistrosSelecionadosSuccess(resp))
            }
        })
    } catch (e) {
        console.log(e)
        dispatch(actions.loadRegistrosSelecionadosFailure())
    }
}

export const setSelectedRegistros = (registrosSelecionados) => async (dispatch, getState) => {
    try {
        console.log(registrosSelecionados);
        
        dispatch(actions.setSelectedRegistrosInProgress())

        dispatch(actions.setSelectedRegistrosSuccess(registrosSelecionados))

    } catch (e) {
        dispatch(actions.setSelectedRegistrosFailure())
    }
}

export const loadRegistro = (registroId) => async (dispatch, getState) => {
    try {
        dispatch(actions.loadRegistroPorIdInProgress())

        api.getRegistro(registroId).then((resp) => {
            if (resp === null || resp === '') {
                dispatch(actions.loadRegistroPorIdFailure())
            } else {
                dispatch(actions.loadRegistroPorIdSuccess(resp))
            }
        })
    } catch (e) {
        dispatch(actions.loadRegistroPorIdFailure())
    }
}

export const loadAnotacoes = (registroId) => async (dispatch, getState) => {
    try {
        dispatch(actions.loadAnotacoesInProgress())

        api.getRegistroAnotacoesPorID(registroId).then((resp) => {
            if (resp === null || resp === '') {
                dispatch(actions.loadAnotacoesFailure())
            } else {
                dispatch(actions.loadAnotacoesSuccess(resp))
            }
        })
    } catch (e) {
        dispatch(actions.loadAnotacoesFailure())
    }
}

export const loadReconhecimentos = () => async (dispatch, getState) => {
    try {
        dispatch(actions.loadReconhecimentosInProgress())

        api.getReconhecimentos().then((resp) => {
            if (resp === null || resp === '') {
                dispatch(actions.loadReconhecimentosFailure())
            } else {
                dispatch(actions.loadReconhecimentosSuccess(resp))
            }
        })
    } catch (e) {
        dispatch(actions.loadReconhecimentosFailure())
    }
}

export const loadAtosRegulatoriosComVinculosCursosReconhecimentos = () => async (dispatch, getState) => {
    try {
        dispatch(actions.loadAtosRegulatoriosReconhecimentosInProgress())

        api.getAtosRegulatoriosComVinculoCursoReconhecimento().then((resp) => {
            if (resp === null || resp === '') {
                dispatch(actions.loadAtosRegulatoriosReconhecimentosFailure())
            } else {
                dispatch(actions.loadAtosRegulatoriosReconhecimentosSuccess(resp))
            }
        })
    } catch (e) {
        dispatch(actions.loadAtosRegulatoriosReconhecimentosFailure())
    }
}

export const loadAtosRegulatoriosComVinculosCursosAutorizacao = () => async (dispatch, getState) => {
    try {
        dispatch(actions.loadAtosRegulatoriosAutorizacaoCursoInProgress())

        api.getAtosRegulatoriosComVinculoCursoAutorizacao().then((resp) => {
            
            if (resp === null || resp === '') {
                dispatch(actions.loadAtosRegulatoriosAutorizacaoCursoFailure())
            } else {
                dispatch(actions.loadAtosRegulatoriosAutorizacaoCursoSuccess(resp))
            }
        })
    } catch (e) {
        dispatch(actions.loadAtosRegulatoriosAutorizacaoCursoFailure())
    }
}

export const addNovaDataPublicacaoRequest = (data, id) => async (dispatch) => {
    try {
        const newData = JSON.stringify({
            DataPublicacao: data,
            RegistroID: Number(id),
        })
        
        api.gravarDataPublicacaoRegistro(newData).then((resp) => {
            //if (resp === null || resp === '') {
            //    dispatch(displayAlert(resp))
            //} else {
            dispatch(actions.addNovaDataPublicacao())
            //}
        })
    } catch (e) {
        dispatch(displayAlert(e))
    }
}

export const addNovaDataPublicacaoEmLoteRequest = (data, registrosSelecionados) => async (dispatch) => {
    try {
        Swal.fire({
            showCancelButton: false, // There won't be any cancel button
            showConfirmButton: false, // There won't be any confirm button
            title: 'Por favor aguarde!',
            html: 'Salvando data de publicaçãos.',
            allowOutsideClick: false,
            willOpen: () => {
                Swal.showLoading()
            },
        })
        let ids = registrosSelecionados.map(a => a.registroID);

        const newData = JSON.stringify({
            DataPublicacao: data,
            Registros: ids,
        })
        
        api.gravarDataPublicacaoRegistroEmLote(newData).then((resp) => {
            Swal.close()            
            Swal.fire({
            icon: 'success',
            title: 'Data de publicação cadastrada com sucesso! Redirecionando...',
            showConfirmButton: false,
            timer: 4000,
            }).then(dispatch(actions.addNovaDataPublicacaoEmLote())) 
        })
    } catch (e) {        
        Swal.fire({
            icon: 'error',
            title: 'Erro ao salvar data de publicação em lote!',
            html: 'Verifique se os campos obrigatórios foram preenchidos e tente novamente. Se erro persistir entre em contato com o administrador do sistema.'
        })
        //dispatch(displayAlert(e))
    }
}

export const addNewReconhecimentoRequest = (data, id) => async (dispatch) => {
    try {
        const newData = JSON.stringify({
            TipoAtoRegulatorio: this.state.TipoAtoRegulatorio,
            Numero: Number(this.state.Numero),
            Data: this.state.Data,
            VeiculoPublicacao: this.state.VeiculoPublicacao,
            DataPublicacao: this.state.DataPublicacao,
            SecaoPublicacao: this.state.SecaoPublicacao,
            PaginaPublicacao: this.state.PaginaPublicacao,
            Habilitacao: this.state.Habilitacao,
            ProcessoReconhecimento: this.state.ProcessoReconhecimento,  
        })
        api.gravarDataPublicacaoRegistro(newData).then((resp) => {
            //if (resp === null || resp === '') {
            //    dispatch(displayAlert(resp))
            //} else {
            dispatch(actions.addNovaDataPublicacao())
            //}
        })
    } catch (e) {
        dispatch(displayAlert(e))
    }
}

export const cancelarRegistroRequest = (
    registroId,
    justificativa,
    responsavel,
    responsavelDocumento
) => async (dispatch) => {
    try {
        const dadosCancelamento = JSON.stringify({
            registroId: Number(registroId),
            justificativa,
            responsavel,
            responsavelDocumento,
        })
        api.cancelarRegistro(dadosCancelamento, registroId).then((resp) => {
            //if (resp === null || resp === '') {
            //    dispatch(displayAlert(resp))
            //} else {
            dispatch(actions.cancelaRegistro(registroId))
            //}
        })
    } catch (e) {
        dispatch(displayAlert(e))
    }
}

export const revogaCancelamentoRegistroRequest = (
    registroId,
    justificativa,
    responsavel,
    responsavelDocumento
) => async (dispatch) => {
    try {
        const dadosRevogarCancelamento = JSON.stringify({
            registroId: Number(registroId),
            justificativa,
            responsavel,
            responsavelDocumento,
        })
        api.revogarRegistro(dadosRevogarCancelamento, registroId).then(
            (resp) => {
                // if (resp === null || resp === '') {
                //    dispatch(displayAlert(resp))
                //} else {
                dispatch(actions.revogaCancelamentoRegistro(registroId))
                //}
            }
        )
    } catch (e) {
        dispatch(displayAlert(e))
    }
}

export const addNovoRegistroRequest = (dadosRegistro) => async (dispatch) => {
    try {
        dispatch(actions.addNovoRegistroInProgress())
        const newData = JSON.stringify(dadosRegistro)
        api.gravarNovoRegistro(newData).then((resp) => {
            if (resp === '400') {
                dispatch(actions.addNovoRegistroFailure(resp))
            } else {
                dispatch(actions.addNovoRegistroSuccess(resp))
            }
        })
    } catch (e) {
        dispatch(actions.addNovoRegistroFailure())
    }
}

export const loadCampus = () => async (dispatch, getState) => {
    try {
        dispatch(actions.loadCampusInProgress())

        api.getCampus().then((resp) => {
            if (resp === null || resp === '') {
                dispatch(actions.loadCampusFailure())
            } else {
                dispatch(actions.loadCampusSuccess(resp))
            }
        })
    } catch (e) {
        dispatch(actions.loadCampusFailure())
    }
}

export const loadAnos = () => async (dispatch, getState) => {
    try {
        dispatch(actions.loadAnosInProgress())
        var date = new Date()
        var anos = []
        const ano = date.getFullYear()

        for (var i = ano; i > ano - 5; i--) {
            anos.push(i)
        }
        dispatch(actions.loadAnosSuccess(anos))
    } catch (e) {
        dispatch(actions.loadAnosFailure())
    }
}

export const loadDatasColacao = (campus, ano) => async (dispatch, getState) => {
    try {
        dispatch(actions.loadDatasColacaoInProgress())

        api.getDatasColacao(campus, ano).then((resp) => {
            if (resp === null || resp === '') {
                dispatch(actions.loadDatasColacaoFailure())
            } else {
                dispatch(actions.loadDatasColacaoSuccess(resp))
            }
        })
    } catch (e) {
        dispatch(actions.loadDatasColacaoFailure())
    }
}

export const getDadosFormando = (rga) => async (dispatch, getState) => {
    try {
        await api.getDadosFormando(rga).then((resp) => {
            return resp
        })
    } catch (e) {
        dispatch(actions.loadDadosFormandoFailure())
    }
}

export const loadDadosFormando = (rga) => async (dispatch, getState) => {
    try {
        dispatch(actions.loadDadosFormandoInProgress())

        api.getDadosFormando(rga).then((resp) => {
            if (resp === null || resp === '') {
                dispatch(actions.loadDadosFormandoFailure())
            } else {
                /*
                loadUFs()
                loadMunicipios()
                loadCurso()
                loadCursosGraus()
                loadCursoFormacoes()
                loadCursoTitulacoes()
                loadCursoReconhecimentos()
                loadIESEmissora(1)
                loadIESRegistradora(1)
                loadLivros()
                loadEstudanteEnade()
                */
                dispatch(actions.loadDadosFormandoSuccess(resp))
            }
        })
    } catch (e) {
        dispatch(actions.loadDadosFormandoFailure())
    }
}

export const dispatchChaining = (rga) => async (dispatch) => {
    await Promise.all([
        // dispatch(loadDadosFormando(rga)),
        dispatch(loadUFs()),
        dispatch(loadCursosGraus()),
        dispatch(loadIESEmissora(1)), //TODO
        dispatch(loadIESRegistradora(1)), //TODO
        dispatch(loadLivros()),
        /*
        dispatch(loadMunicipios()),
        dispatch(loadCurso()),
        dispatch(loadCursoFormacoes()),
        dispatch(loadCursoTitulacoes()),
        dispatch(loadCursoReconhecimentos()),
        dispatch(loadEstudanteEnade()),
        */
    ])

    return dispatch(loadDone())
}

export const loadUFs = () => async (dispatch, getState) => {
    try {
        dispatch(actions.loadUFsInProgress())

        api.getUFs().then((resp) => {
            if (resp === null || resp === '') {
                dispatch(actions.loadUFsFailure())
            } else {
                dispatch(actions.loadUFsSuccess(resp))
            }
        })
    } catch (e) {
        dispatch(actions.loadUFsFailure())
    }
}

export const loadMunicipios = (uf) => async (dispatch, getState) => {
    try {
        let state = getState('estudante')
        dispatch(actions.loadMunicipiosInProgress())

        let natudalidadeUF = uf ? uf : state.registros.estudante.NaturalUF

        api.getMunicipios(natudalidadeUF).then((resp) => {
            if (resp === null || resp === '') {
                dispatch(actions.loadMunicipiosFailure())
            } else {
                dispatch(actions.loadMunicipiosSuccess(resp))
            }
        })
    } catch (e) {
        dispatch(actions.loadMunicipiosFailure())
    }
}

export const loadCurso = () => async (dispatch, getState) => {
    try {
        let state = getState('estudante')
        dispatch(actions.loadCursoInProgress())

        alert(state.registros.estudante.codCurso)
        api.getCurso(state.registros.estudante.codCurso).then((resp) => {
            if (resp === null || resp === '') {
                dispatch(actions.loadCursoFailure())
            } else {
                dispatch(actions.loadCursoSuccess(resp))
            }
        })
    } catch (e) {
        dispatch(actions.loadCursoFailure())
    }
}

export const loadCursosGraus = () => async (dispatch, getState) => {
    try {
        dispatch(actions.loadCursosGrausInProgress())

        api.getCursosGraus().then((resp) => {
            if (resp === null || resp === '') {
                dispatch(actions.loadCursosGrausFailure())
            } else {
                dispatch(actions.loadCursosGrausSuccess(resp))
            }
        })
    } catch (e) {
        dispatch(actions.loadCursosGrausFailure())
    }
}

export const loadCursoFormacoes = () => async (dispatch, getState) => {
    try {
        let state = getState('estudante')
        dispatch(actions.loadCursoFormacoesInProgress())

        api.getCursoFormacoes(state.cursos.curso.cursoID).then((resp) => {
            if (resp === null || resp === '') {
                dispatch(actions.loadCursoFormacoesFailure())
            } else {
                dispatch(actions.loadCursoFormacoesSuccess(resp))
            }
        })
    } catch (e) {
        dispatch(actions.loadCursoFormacoesFailure())
    }
}

export const loadCursoTitulacoes = () => async (dispatch, getState) => {
    try {
        let state = getState('estudante')
        dispatch(actions.loadCursoTitulacoesInProgress())

        api.getCursoTitulacoes(state.cursos.curso.cursoID).then((resp) => {
            if (resp === null || resp === '') {
                dispatch(actions.loadCursoTitulacoesFailure())
            } else {
                dispatch(actions.loadCursoTitulacoesSuccess(resp))
            }
        })
    } catch (e) {
        dispatch(actions.loadCursoTitulacoesFailure())
    }
}

export const loadCursoReconhecimentos = () => async (dispatch, getState) => {
    try {
        let state = getState('estudante')
        dispatch(actions.loadCursoReconhecimentosInProgress())

        api.getCursoReconhecimentos(state.cursos.curso.cursoID).then((resp) => {
            if (resp === null || resp === '') {
                dispatch(actions.loadCursoReconhecimentosFailure())
            } else {
                dispatch(actions.loadCursoReconhecimentosSuccess(resp))
            }
        })
    } catch (e) {
        dispatch(actions.loadCursoReconhecimentosFailure())
    }
}

export const loadIESEmissora = (iesId) => async (dispatch, getState) => {
    try {
        dispatch(actions.loadIesEmissoraInProgress())

        api.getIes(iesId).then((resp) => {
            if (resp === null || resp === '') {
                dispatch(actions.loadIesEmissoraFailure())
            } else {
                dispatch(actions.loadIesEmissoraSuccess(resp))
            }
        })
    } catch (e) {
        dispatch(actions.loadIesEmissoraFailure())
    }
}

export const loadIESRegistradora = (iesId) => async (dispatch, getState) => {
    try {
        dispatch(actions.loadIesRegistradoraInProgress())

        api.getIes(iesId).then((resp) => {
            if (resp === null || resp === '') {
                dispatch(actions.loadIesRegistradoraFailure())
            } else {
                dispatch(actions.loadIesRegistradoraSuccess(resp))
            }
        })
    } catch (e) {
        dispatch(actions.loadIesRegistradoraFailure())
    }
}

export const loadEstudanteEnade = () => async (dispatch, getState) => {
    try {
        let state = getState('estudante')
        dispatch(actions.loadEstudanteEnadeInProgress())

        api.getEstudanteEnade(state.registros.estudante.matricula).then(
            (resp) => {
                if (resp === null || resp === '') {
                    dispatch(actions.loadEstudanteEnadeFailure())
                } else {
                    dispatch(actions.loadEstudanteEnadeSuccess(resp))
                }
            }
        )
    } catch (e) {
        dispatch(actions.loadEstudanteEnadeFailure())
    }
}

export const loadDone = () => async (dispatch, getState) => {
    try {
    } catch (e) {
        dispatch(actions.loadMunicipiosFailure())
    }
}
