import apiAxios from './apiAxios'

const options = {
    headers: { 'content-type': 'application/json' },
}

export const fetchLivros = () => {
    let url = `/api/livro`
    return apiAxios
        .get(url)
        .then((resp) => resp.data)
        .catch((ex) =>
            console.log(`O seguinte erro foi gerado: ` + ex, 'error')
        )
}

export async function getEstudantesFormandosIndividual(dto) {
    let url = '/api/Aluno/formado'
    return apiAxios
        .post(url, dto, options)
        .then((resp) => resp.data)
        .catch((ex) =>
            console.log(`O seguinte erro foi gerado: ` + ex, 'error')
        )
}

export async function getEstudantesFormandos(dataColacaoId) {
    let url = '/api/colacao/estudantes/' + dataColacaoId
    const result = apiAxios
        .get(url)
        .then((resp) => resp.data)
        .catch((ex) =>
            console.log(`O seguinte erro foi gerado: ` + ex, 'error')
        )
    return result
}

export async function getDisciplinasSemProfessor(rga) {
    const url = `/api/Aluno/disciplinas-sem-professor/${rga}`;
    const result = apiAxios
        .get(url)
        .then((resp) => resp.data)
        .catch((ex) =>
            console.log(`O seguinte erro foi gerado: ` + ex, 'error')
    )
    return result;
}

export async function getDisciplinasComPendencia(rga) {
    const url = `/api/Aluno/disciplinas-com-pendencia/${rga}`;
    const result = apiAxios
        .get(url)
        .then((resp) => resp.data)
        .catch((ex) =>
            console.log(`O seguinte erro foi gerado: ` + ex, 'error')
        )
    return result;
}

export const fetchRegistros = () => {
    let url = `/api/registro`
    return apiAxios
        .get(url)
        .then((resp) => resp.data)
        .catch((ex) =>
            console.log(`O seguinte erro foi gerado: ` + ex, 'error')
        )
}

export const fetchRegistrosPeriodo = (dataInicio, dataFim) => {
    let url = `/api/registro/periodo`
    let obj = {
        DataInicio: dataInicio,
        DataFim: dataFim,
    }
    console.log(obj)
    return apiAxios
        .post(url, obj, options)
        .then((resp) => resp.data)
        .catch((ex) =>
            console.log(`O seguinte erro foi gerado: ` + ex, 'error')
        )
}

export const fetchRegistrosDiplomaDigitalEmitido = () => {
    let url = `/api/registro/diploma-digital-emitidos`
    return apiAxios
        .get(url)
        .then((resp) => resp.data)
        .catch((ex) =>
            console.log(`O seguinte erro foi gerado: ` + ex, 'error')
        )
}

export const fetchRegistrosSelecionados = (registrosSelecionados) => {
    let url = `/api/registro/selecionados`
    let obj = {
        RegistrosSelecionados: registrosSelecionados
    }
    return apiAxios
        .post(url, obj, options)
        .then((resp) => resp.data)
        .catch((ex) =>
            console.log(`O seguinte erro foi gerado: ` + ex, 'error')
        )
}

export const getRegistro = (id) => {
    let url = `/api/registro/${id}`
    return apiAxios.get(url)
        .then((resp) => resp.data)
        .catch((ex) =>
            console.log(`O seguinte erro foi gerado: ` + ex, 'error')
        )
}

export const getRegistroAnotacoesPorID = (id) => {
    let url = `/api/registro/anotacoes/${id}`
    return apiAxios
        .get(url)
        .then((resp) => resp.data)
        .catch((ex) =>
            console.log(`O seguinte erro foi gerado: ` + ex, 'error')
        )
}

export const getReconhecimentos = () => {
    let url = '/api/reconhecimento'
    return apiAxios
        .get(url)
        .then((resp) => resp.data)
        .catch((ex) =>
            console.log(`O seguinte erro foi gerado: ` + ex, 'error')
        )
}

export const getReconhecimentosCursos = () => {
    let url = '/api/reconhecimento/cursos'
    return apiAxios
        .get(url)
        .then((resp) => resp.data)
        .catch((ex) =>
            console.log(`O seguinte erro foi gerado: ` + ex, 'error')
        )
}

export const getAtosRegulatoriosComVinculoCursoReconhecimento = () => {
    
    let url = '/api/reconhecimento/atos'
    return apiAxios
        .get(url)
        .then((resp) => resp.data)
        .catch((ex) =>
            console.log(`O seguinte erro foi gerado: ` + ex, 'error')
        )
}

export const getAtosRegulatoriosComVinculoCursoAutorizacao = () => {
    
    //const url = 'https://localhost:5001/api/curso/autorizacao/atos'; 
    let url = '/api/curso/autorizacao/atos';
    return apiAxios
        .get(url)
        .then((resp) => resp.data)
        .catch((ex) =>
            console.log(`O seguinte erro foi gerado: ` + ex, 'error')
        )
}

export const gravarDataPublicacaoRegistro = (newData) => {
    //let url = 'https://localhost:5001/api/registro/publicacao'
    let url = '/api/registro/publicacao'
    return apiAxios
        .post(url, newData, options)
        .then((resp) => resp.data)
        .catch((ex) =>
            console.log(`O seguinte erro foi gerado: ` + ex, 'error')
        )
}

export const gravarDataPublicacaoRegistroEmLote = (newData) => {
    //let url = 'https://localhost:5001/api/registro/publicacao'
    let url = '/api/registro/publicacao-lote/'
    return apiAxios
        .post(url, newData, options)
        .then((resp) => resp.data)
        .catch((ex) =>
            console.log(`O seguinte erro foi gerado: ` + ex, 'error')
        )
}

export const cancelarRegistro = (dadosCancelamento, registroId) => {
    let url = '/api/registro/cancelar/' + registroId
    console.log(dadosCancelamento)
    const result = apiAxios
        .post(url, dadosCancelamento, options)
        .then((resp) => resp)
        .catch((error) => error)
    return result
}
export const revogarRegistro = (dadosRevogarCancelamento, registroId) => {
    let url = '/api/registro/revogarcancelamento/' + registroId
    // return apiAxios
    //     .post(url, dadosRevogarCancelamento)
    //     .then((resp) => resp.data)
    //     .catch((ex) =>
    //         console.log(`O seguinte erro foi gerado: ` + ex, 'error')
    //     )
    console.log(dadosRevogarCancelamento)
    const result = apiAxios
        .post(url, dadosRevogarCancelamento, options)
        .then((resp) => resp)
        .catch((error) => error)
    return result
}

export async function getDadosFormando(rga) {
    let url = `/api/Aluno/detail/${rga}`
    const result = apiAxios.get(url)
        .then((resp) => resp.data)
        .catch((ex) =>
            console.log(`O seguinte erro foi gerado: ` + ex, 'error')
        )
    return result
}

export async function gravarNovoRegistro(newData) {
    let url = '/api/registro'
    const result = apiAxios
        .post(url, newData)
        .then((resp) => resp.data)
        .catch((error) => error.response.status)
    return result
}

export async function getCampus() {
    let url = '/api/colacao/campussiga'
    const result = apiAxios
        .get(url)
        .then((resp) => resp.data)
        .catch((ex) =>
            console.log(`O seguinte erro foi gerado: ` + ex, 'error')
        )
    return result
}

export async function getDatasColacao(campus, ano) {
    let url = '/api/colacao/recentes/ano/campus/' + campus + '/' + ano
    const result = apiAxios
        .get(url)
        .then((resp) => resp.data)
        .catch((ex) =>
            console.log(`O seguinte erro foi gerado: ` + ex, 'error')
        )
    return result
}
export const getUFs = () => {
    let url = '/api/endereco/ufs'
    return apiAxios
        .get(url)
        .then((resp) => resp.data)
        .catch((ex) =>
            console.log(`O seguinte erro foi gerado: ` + ex, 'error')
        )
}
export const getMunicipios = (uf) => {
    let url = '/api/endereco/ufs/municipios/' + uf
    return apiAxios
        .get(url)
        .then((resp) => resp.data)
        .catch((ex) =>
            console.log(`O seguinte erro foi gerado: ` + ex, 'error')
        )
}
export const getCurso = (cursoSigaId) => {
    let url = '/api/curso/siga/' + cursoSigaId
    return apiAxios
        .get(url)
        .then((resp) => resp.data)
        .catch((ex) =>
            console.log(`O seguinte erro foi gerado: ` + ex, 'error')
        )
}
export const getCursosGraus = () => {
    let url = '/api/grau'
    return apiAxios
        .get(url)
        .then((resp) => resp.data)
        .catch((ex) =>
            console.log(`O seguinte erro foi gerado: ` + ex, 'error')
        )
}
export const getCursoFormacoes = (cursoId) => {
    let url = '/api/curso/formacao/' + cursoId
    return apiAxios
        .get(url)
        .then((resp) => resp.data)
        .catch((ex) =>
            console.log(`O seguinte erro foi gerado: ` + ex, 'error')
        )
}
export const getCursoTitulacoes = (cursoId) => {
    let url = '/api/curso/titulacao/' + cursoId
    return apiAxios
        .get(url)
        .then((resp) => resp.data)
        .catch((ex) =>
            console.log(`O seguinte erro foi gerado: ` + ex, 'error')
        )
}
export const getCursoReconhecimentos = (cursoId) => {
    let url = '/api/reconhecimento/curso/' + cursoId
    return apiAxios
        .get(url)
        .then((resp) => resp.data)
        .catch((ex) =>
            console.log(`O seguinte erro foi gerado: ` + ex, 'error')
        )
}
export const getIes = (iesId) => {
    let url = '/api/instituicao/' + iesId
    return apiAxios
        .get(url)
        .then((resp) => resp.data)
        .catch((ex) =>
            console.log(`O seguinte erro foi gerado: ` + ex, 'error')
        )
}

export async function getEstudanteEnade(rga) {
    let url = '/api/Historico/Enade/' + rga
    const result = apiAxios
        .get(url)
        .then((resp) => resp.data)
        .catch((ex) =>
            console.log(`O seguinte erro foi gerado: ` + ex, 'error')
        )
    return result
}

/*-------------------------TODO: a partir daqui as chamadas nao passam pelo thunk---------------*/
export const checkRecaptcha = (recaptchaValue) => {
  /*  const RECAPTCHA_SERVER_KEY = env.RECAPTCHA_SERVER_KEY

    let configCaptcha = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
            'Access-Control-Allow-Origin': '*',
        },
    }
    let toAllowCross = '';//'https://cors-anywhere.herokuapp.com/'
    let url =
        toAllowCross +
        `https://www.google.com/recaptcha/api/siteverify?secret=${RECAPTCHA_SERVER_KEY}&response=${recaptchaValue}`
        
    return apiAxios
        .get(url, configCaptcha)
        .then((resp) => resp.data)
        .catch((ex) =>
        console.log(ex)
            //console.log(`O seguinte erro foi gerado: ` + ex, 'error')
        ) //Swal.fire('Oops', )
*/
    let url = `/api/diploma/localizar/captcha`
    return apiAxios
        .post(url, {
            captcha: recaptchaValue
        })
        //.get(url)
        .then((resp) => resp)
        .catch((ex) =>
            console.log(`O seguinte erro foi gerado: ` + ex, 'error')
        ) //Swal.fire('Oops', )

}

export const getRegistroPorID = (id) => {
    let url = `/api/diploma/dadosregistro/${id}`
    return apiAxios
        .get(url)
        .then((resp) => resp.data)
        .catch((ex) =>
            console.log(`O seguinte erro foi gerado: ` + ex, 'error')
        ) //Swal.fire('Oops', )
}

export const getRegistroPublicoPorID = (id) => {
    let url = `/api/diploma/dadospublicoregistro/${id}`
    return apiAxios
        .get(url)
        .then((resp) => resp.data)
        .catch((ex) =>
            console.log(`O seguinte erro foi gerado: ` + ex, 'error')
        ) //Swal.fire('Oops', )
}

export const fetchRegistrosPorPeriodo = (dataInicio, dataFim) => {
    let url = `/api/registro/busca`
    return apiAxios
        .post(url, {
            DataInicio: dataInicio,
            DataFim: dataFim,
        })
        .then((resp) => resp.data)
        .catch((ex) =>
            console.log(`O seguinte erro foi gerado: ` + ex, 'error')
        ) //Swal.fire('Oops', )
}

export const getRegistroPorCodigoValidacao = (codigoValidacao) => {
    let url = `/api/diploma/dadosregistro/codigo/${codigoValidacao}`
    return apiAxios
        .get(url)
        .then((resp) => resp.data)
        .catch((ex) =>
            console.log(`O seguinte erro foi gerado: ` + ex, 'error')
        ) //Swal.fire('Oops', )
}

export default fetchRegistros